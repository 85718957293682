// @import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// // Import Chart.js custom tooltips styles
// @import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
// @import "example";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 50%;
  max-height: 90%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
}

.btn {
  background-color: white;
  opacity: 0.9;
  transition: background-color 0.1s ease-in;
}

.btn:hover {
  background-color: rgba(211, 211, 211, 1);
}

.border {
  border: 1px solid black;
}
